import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './Home';


import Nav from './Nav';


function App() {
    return (
        <BrowserRouter>
        < Nav />
    
        

            <Routes>
             
                <Route path="/" element={<Home />} />
               

    
            </Routes>
        </BrowserRouter>
    );
}


export default App;


